<ng-template #reviewModal
  let-c="close">
  <app-modal-confirmation [title]="'rw.Please fill in the details'|translate"
    (closeModal)="close()"
    (confirmModal)="submit()">
    <ng-template appCustomModalBody>
      <div class="modal-body p-3 custom-box">
        <form [formGroup]="fg">
          <ng-container *ngFor="let f of formFields">
            <div class="form-group">
              <label [for]="f.name">
                {{ f.label | translate}}<span class="text-danger">*</span>
              </label>
              <ng-container *ngIf="f.type === 'input'">
                <input type="text"
                  class="form-control"
                  [class.is-invalid]="showErrors && fg?.controls?.[f.name]?.errors?.required"
                  [id]="f.name"
                  [placeholder]="f.label | translate"
                  [formControlName]="f.name"
                  [maxlength]="f.limit"
                  autocomplete="off"
                >
              </ng-container>
              <ng-container *ngIf="f.type === 'textarea'">
                <textarea type="text"
                  class="form-control"
                  rows="3"
                  [class.is-invalid]="showErrors && fg?.controls?.[f.name]?.errors?.required"
                  [id]="f.name"
                  [placeholder]="f.label | translate"
                  [formControlName]="f.name"
                  [maxlength]="f.limit"
                  autocomplete="off"
                >
                </textarea>
              </ng-container>
              <small *ngIf="showErrors && fg?.controls?.[f.name]?.errors?.required else charRemain"
                class="text-danger">{{'rw.This field is required.'|translate}}</small>
              <ng-template #charRemain>
                <span class="remain">{{ 'EMAIL-SETTING.CHAR-REMAIN'|translate }}: {{f.limit -
                fg?.controls?.[f.name]?.value?.length}}</span>
              </ng-template>
            </div>
          </ng-container>
        </form>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>
