<div class="custom w-100 d-flex">
  <div class="unlimited" [class.disabled]="unlimited || disabled">
    <svg-icon *ngIf="!unlimited"
      src="assets/images/icons/unlimited.svg"
      class="icon pointer"
      [svgStyle]="{width: 24, height: 24}"
      (click)="toggleUnlimited()"></svg-icon>
    <svg-icon *ngIf="unlimited"
      src="assets/images/icons/unlimited-active.svg"
      class="icon pointer"
      [svgStyle]="{width: 24, height: 24}"
      (click)="toggleUnlimited()"></svg-icon>
  </div>
  <input class="form-control text-right"
    name="number"
    autocomplete="off"
    currencyMask
    [placeholder]="placeholder"
    [ngModel]="maxValue"
    (ngModelChange)="maxValueChange.emit($event)"
    [disabled]="unlimited || disabled" />
</div>
