import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-max-financial-amount',
  templateUrl: './max-financial-amount.component.html',
  styleUrls: ['max-financial-amount.component.scss'],
})
export class MaxFinancialAmountComponent {
  @Input() unlimited = false;
  @Output() unlimitedChange = new EventEmitter<boolean>();

  @Input() maxValue;
  @Output() maxValueChange = new EventEmitter();

  @Input() placeholder = 'Maximum Financial Amount';
  @Input() disabled = false;

  toggleUnlimited() {
    if (this.disabled) {
      return;
    }
    this.unlimited = !this.unlimited;
    this.unlimitedChange.emit(this.unlimited);

    if (this.unlimited) {
      this.maxValue = null;
      this.maxValueChange.emit(null);
    }
  }
}
