import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ReviewForm } from './review-modal.model';

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent {
  @Output() confirm = new EventEmitter<ReviewForm>();
  @Output() closeModal = new EventEmitter();

  @ViewChild('reviewModal', { static: false })
  reviewModal: ElementRef;

  @ViewChild('formRef', { static: false })
  formRef: FormGroupDirective;

  fg: FormGroup;
  showErrors = false;

  readonly formFields = [
    {
      label: 'rw.Rigishu / Flowlite / Approval Number',
      name: 'reviewer_approval_number',
      limit: 255,
      type: 'input',
    },
    {
      label: 'rw.Comments',
      name: 'reviewer_comment',
      limit: 500,
      type: 'textarea',
    },
    {
      label: 'rw.Section',
      name: 'reviewer_section',
      limit: 255,
      type: 'input',
    },
    {
      label: 'rw.Div/Dev',
      name: 'reviewer_dept',
      limit: 255,
      type: 'input',
    },
  ];

  constructor(
    public modalService: NgbModal,
    private fb: FormBuilder,
  ) {}

  open(): void {
    this.fg = this.fb.group({
      reviewer_approval_number: [null, Validators.required],
      reviewer_comment: [null, Validators.required],
      reviewer_section: [null, Validators.required],
      reviewer_dept: [null, Validators.required],
    });

    this.modalService.open(this.reviewModal, {
      backdrop: 'static',
      centered: true,
    });
  }

  close(): void {
    this.showErrors = false;
    this.fg.reset();
    this.modalService.dismissAll();
    this.closeModal.emit();
  }

  submit() {
    if (!this.fg.valid) {
      this.showErrors = true;
      return;
    }
    this.confirm.emit(this.fg.value);
  }
}
