<div class="pdf-view"
    [ngClass]="getPdfViewClass()"
    [ngStyle]="{'height' :getHeight()}">
  <div class="page-control w-100 position-relative justify-content-between align-items-center">
    <!-- zoom col -->
    <div class="col-auto row no-gutters"
         [style.--c]="themeList?.header_table_color">
      <div class="theme-icons pointer mr-2"
           (click)="zoomOut()">
        <svg-icon src="assets/svg/icon-usage/minus-circle.svg"></svg-icon>
      </div>
      <div class="theme-icons pointer mr-2"
           (click)="zoomIn()">
        <svg-icon src="assets/svg/icon-usage/plus-circle.svg"></svg-icon>
      </div>

      <div ngbDropdown
           class="d-inline-block"
           autoClose="true">
        <button class="btn page-btn bg-white mx-1 f-weight-400"
                ngbDropdownToggle>{{selectedZoom?.label}}</button>
        <div class="zoom-dropdown" ngbDropdownMenu>
          <ng-container *ngFor="let setting of zoomSettings; let index = index">
            <div class="m-x-5 m-y-5">
              <button ngbDropdownItem
                      [ngClass]="{'active': setting.value === selectedZoom?.value}"
                      (click)="changeScaleFromDropDown(index)">
                {{ setting.label }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!--  CHANGE PAGE COL -->
    <div class="col-auto row no-gutters">
    <svg-icon src="assets/images/icons/back2.svg"
        class="pointer"
        [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
        style="width: 20px;height: 25px;margin-right: 10px;"
        (click)="changeDocumentPageBack()">
    </svg-icon>
    <div ngbDropdown
        class="d-inline-block"
        autoClose="true">
      <span>{{"UPLOAD.PAGE"|translate}}</span>
      <button class="btn page-btn mx-1"
          ngbDropdownToggle>{{currentDocumentPage}}</button>
      <span>{{"UPLOAD.OF"|translate}}&nbsp;{{totalDocumentPage}}</span>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let page of documentPages">
          <div style="margin: 5px;">
            <button ngbDropdownItem
                [ngClass]="{'active': page === currentDocumentPage}"
                (click)="changeDocumentPage(page)">{{page}}</button>
          </div>
        </ng-container>
      </div>
    </div>
    <svg-icon src="assets/images/icons/next2.svg"
        class="pointer"
        [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
        style="width: 20px;height: 25px;margin-left: 10px;"
        (click)="changeDocumentPageNext()">
    </svg-icon>
    </div>

    <!-- DOWNLOAD COL  -->
    <div class="col-auto row no-gutters position-relative">
    <div *ngIf="showDownload"
        class="d-none d-md-block icon pointer p-1"
        style="right: 10px"
        (click)="downloaded.emit()">
      <svg-icon src="assets/images/icon_download2.svg"
          [svgStyle]="{'width.px': 13, 'height.px': 13}"
          [style.--c]="themeList?.header_table_color"></svg-icon>
      <span [style.color]="themeList?.header_table_color"
          class="underline ml-2">{{ 'MEMOS.DOWNLOAD'|translate }}</span>
    </div>
    </div>
  </div>

  <div *ngIf="!pdf"
      class="scrollbar-large pdf-shadow bg-color d-flex w-100 page-view justify-content-center align-items-center">
    <div class="custom-pdf-view">
      <div class="spinner-center ">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </div>
  </div>

  <div class="pdf-shadow"
      [ngClass]="getPdfViewerClass()"
      (click)="clickPreview.emit()">
    <div class="p-md-4 p-2">
      <div class="custom-pdf-view"
          #pdfView
          (resized)="pageRendered()">
        <pdf-viewer [src]="{url: pdfFile, httpHeaders: (_featureFlag.media_url_need_authentication ? httpHeaders:null)}"
            [ngStyle]="{'height': height + 'px', 'width': width + 'px'}"
            [(page)]="currentDocumentPage"
            [ngClass]="{'pdf-viewer-scrollbar-gutter-stable': scrollbarGutterStable}"
            [render-text]="true"
            [render-text-mode]="1"
            [original-size]="false"
            [autoresize]="true"
            [stick-to-page]="false"
            [fit-to-page]="true"
            [show-all]="false"
            [show-borders]="false"
            (page-rendered)="pageRendered()"
            [c-maps-url]="'/assets/cmaps/'"
            (after-load-complete)="onLoadPdfComplete($event)">
        </pdf-viewer>
      </div>
    </div>
  </div>
</div>
