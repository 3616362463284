export const FONT_LIST = [
  { name: 'THSarabunNew', value: 'THSarabunNew' },
  { name: 'THSarabunNewBold', value: 'THSarabunNewBold' },
  { name: 'THSarabunNewItalic', value: 'THSarabunNewItalic' },
  { name: 'THSarabunNewBoldItalic', value: 'THSarabunNewBoldItalic' },
];

export const DATE_TYPE_LIST = [
  {
    value: 'published_at',
    label: 'Publish Date',
    th: 'วันที่เผยแพร่',
  },
  {
    value: 'finished_at',
    label: 'Finished Date',
    th: 'วันที่เสร็จสิ้น',
  },
  {
    value: 'custom_date',
    label: 'Custom Date',
    th: 'กำหนดวันที่เอง',
  },
];

export const SIGNATURE_COLOR_LIST = [
  '#000000',
  '#4169e1',
  '#EB2B36',
  '#266D1F',
];

export const ZOOM_SETTINGS = [
  {
    label: '10%',
    value: 0.1,
  },
  {
    label: '20%',
    value: 0.2,
  },
  {
    label: '30%',
    value: 0.3,
  },
  {
    label: '40%',
    value: 0.4,
  },
  {
    label: '50%',
    value: 0.5,
  },
  {
    label: '60%',
    value: 0.6,
  },
  {
    label: '70%',
    value: 0.7,
  },
  {
    label: '80%',
    value: 0.8,
  },
  {
    label: '90%',
    value: 0.9,
  },
  {
    label: '100%',
    value: 1,
  },
  {
    label: '110%',
    value: 1.1,
  },
  {
    label: '120%',
    value: 1.2,
  },
  {
    label: '130%',
    value: 1.3,
  },
  {
    label: '140%',
    value: 1.4,
  },
  {
    label: '150%',
    value: 1.5,
  },
  {
    label: '160%',
    value: 1.6,
  },
  {
    label: '170%',
    value: 1.7,
  },
  {
    label: '180%',
    value: 1.8,
  },
  {
    label: '190%',
    value: 1.9,
  },
  {
    label: '200%',
    value: 2.0,
  },
];
